<template>
    <div>
        <img src="@/assets/solutionpdf/container_1.jpg" alt="">
        <img src="@/assets/solutionpdf/container_2.jpg" alt="">
        <img src="@/assets/solutionpdf/container_3.jpg" alt="">
        <img src="@/assets/solutionpdf/container_4.jpg" alt="">
        <img src="@/assets/solutionpdf/container_5.jpg" alt="">
    </div>
</template>

<script>
export default {
    name: "container-solution"
}
</script>

<style scoped>
img{
    width: 100%;
}
</style>